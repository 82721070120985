import { fetchGraphQL } from '@/lib/contentful/api'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { parseEmbeddedMarkdown } from '@/lib/helpers/preview'
import { link } from 'fs'

export const enableHeroPromoPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
        modulePromoVideo(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          inset
          overlayType
          backgroundImage{
            url
          }
          eyebrow
          headline
      }
    }`,
    preview
  )
  const data = entry?.data?.modulePromoVideo
  return { ...data, backgroundImage: data?.backgroundImage?.url }
}

export const enableContent2BlocksPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
        moduleContent2Blocks(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
                backgroundImage{
                  url
                }
                overlayType
                imageSize
                contentAboveTitle{
                    json
                }
                generalSectionEyebrow
                generalSectionTitle
                generalSectionSubtitle
                inset
                generalPaddingDisable
                imagePosition
                eyebrow
                headline
                bodyText {
                  json
                  links {
                    entries {
                      block {
                        sys {
                          id
                        }
                        __typename
                        ... on ModuleMarkdownText {
                          markdownText
                        }
                      }
                    }
                  }
                }

                primaryCta {
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }

                secondaryCta {
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }

                # deprecated
                ctaLeftText
                ctaRightText
                ctaLeftUrl
                ctaRightUrl
      }
    }`,
    preview
  )
  const data = entry?.data?.moduleContent2Blocks

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data.bodyText?.links?.entries?.block

  if (contentLinks && data.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }

  return {
    ...data,
    bodyText: data.bodyText?.json,
    backgroundImage: data.backgroundImage.url,
    contentAboveTitle: data.contentAboveTitle?.json,
  }
}

export const enableContent3BlocksPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleContent3Blocks(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          overlayType
          backgroundImage{
            url
          }
          eyebrow
          headline
          bodyText{
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on ModuleMarkdownText {
                    markdownText
                  }
                }
              }
            }
          }

          eyebrow2
          headline2
          bodyText2 {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on ModuleMarkdownText {
                    markdownText
                  }
                }
              }
            }
          }

          eyebrow3
          headline3
          bodyText3 {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on ModuleMarkdownText {
                    markdownText
                  }
                }
              }
            }
          }

          ctaLeftPrimary {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }
          ctaLeftSecondary {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }
          ctaRightTop {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }
          ctaRightBottom {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }

          # deprecated
          ctaLeftUrl
          ctaRightUrl
          ctaLeftText
          ctaRightText
          ctaLeftUrl2
          ctaLeftText2
          ctaLeftUrl3
          ctaLeftText3
      }
  }`,
    preview
  )
  const data = entry?.data?.moduleContent3Blocks

  const contentLinks1 = data.bodyText?.links?.entries?.block

  if (contentLinks1 && data.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }

  const contentLinks2 = data.bodyText2?.links?.entries?.block

  if (contentLinks2 && data.bodyText2?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText2')
  }

  const contentLinks3 = data.bodyText3?.links?.entries?.block

  if (contentLinks3 && data.bodyText3?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText3')
  }

  return {
    ...data,
    bodyText: data.bodyText?.json,
    bodyText2: data.bodyText2?.json,
    bodyText3: data.bodyText3?.json,
    backgroundImage: data.backgroundImage?.url,
  }
}

export const enableHero1UpSectionPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleHero1UpSection(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        fullHeight
        inset
        backgroundImage{
          url
        }
        overlayType
        useParallaxBackground
        eyebrow
        eyebrowMobile
        headline
        bodyText{
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on ModuleMarkdownText {
                  markdownText
                }
              }
            }
          }
        }
        contentPosition
        ctaLeftText
        ctaLeftUrl
        ctaRightText
        ctaRightUrl
        textContentFullWidth
        smallerLetterSpacing
        hiddenBodyTextOnMobile
        sectionType
        contentPositionOnMobile
        spiceBottomEnable
        bodyTextSize
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleHero1UpSection

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data.bodyText?.links?.entries?.block

  if (contentLinks && data.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }

  return {
    ...data,
    bodyText: data.bodyText?.json,
    backgroundImage: data.backgroundImage?.url,
  }
}

export const enableSlidersPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleSliders(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        sectionPaddingTop
        sys{
          id
        }
        sliderType
        eyebrow
        title
        titleItalicEnable

        subtitleCta {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }

        cta {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        categoryId
        slidesCollection{
          items{
            ...on ProfileModuleProduct{
              moduleName
              productId
            }
            ... on ContentBlock{
              coverImage{
                url
                width
                height
              }
              eyebrow
              title
              timeToRead
              excerpt
              slug
              sys{
                id
              }
            }
          }
        }
        background
        useSearchSpring
        searchSpringProfileTag
        searchspringNoOfProducts
        searchspringPdpProductId
        searchspringCategories
        showProgressBar
        # deprecated
        subtitle
        subtitleUrl
        buttonUrl
        buttonTitle
    }
  }`,
    preview
  )
  return {
    ...entry?.data?.moduleSliders,
    contentBlock: entry?.data?.moduleSliders?.slidesCollection?.items,
  }
}

export const enableHeroSectionPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleHeroSection(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        spaceBelowContent,
        fullHeight,
        inset,
        backgroundImage{
          url
        }
        overlayType
        eyebrow
        spaceBelowTextContent
        useParallaxBackground
        headline

        ctaLeft {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        ctaRight {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }

        bodyText{
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on ModuleMarkdownText {
                  markdownText
                }
              }
            }
          }
        }
        modules{
          sys{
              id
            }
          __typename
        }
        heroType
        showCta
        menuEnable
        firstOnPage
        # deprecated
        ctaLeftUrl
        ctaLeftText
        ctaRightUrl
        ctaRightText
        isPlp
    }
  }`,
    preview
  )

  const data = entry?.data?.moduleHeroSection

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data?.bodyText?.links?.entries?.block

  if (contentLinks && data?.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }
  return {
    ...data,
    bodyText: data?.bodyText?.json,
    backgroundImage: data?.backgroundImage?.url,
  }
}

export const enableContent2ImagesPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleContent2Images(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        sys{
          id
        }
        overlayType
        backgroundImage{
          url
        }
        firstBackgroundLink
        backgroundImage2{
          url
        }
        secondBackgroundLink
        generalPaddingDisable
        eyebrow
        headline
        bodyText{
          json
        }

        eyebrow2
        headline2
        bodyText2{
          json
        }

        ctaLeft1 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        ctaRight1 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        ctaLeft2 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        ctaRight2 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        layout
        stackOrSlide
        # deprecated
        ctaLeftText
        ctaLeftUrl
        ctaRightText
        ctaRightUrl
        ctaLeftText2
        ctaLeftUrl2
        ctaRightUrl2
        ctaRightText2
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleContent2Images
  return {
    ...data,
    bodyText: data.bodyText?.json,
    bodyText2: data.bodyText2?.json,
    backgroundImage: data.backgroundImage?.url,
    backgroundImage2: data.backgroundImage2?.url,
  }
}

export const enable3UpText = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleGlobalBrands(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        buttonTitle
        generalTitle
        generalSubtitle
        inset
        sectionName
        headline
        bodyText{
          json
        }

        headline2
        bodyText2{
          json
        }

        headline3
        bodyText3{
          json
        }
        cta1 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        cta2 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }
        cta3 {
          entryTitle
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }

        # deprecated
        ctaRightUrl
        ctaRightText
        ctaRightUrl2
        ctaRightText2
        ctaRightText3
        ctaRightUrl3
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleGlobalBrands
  return {
    ...data,
    bodyText: data.bodyText?.json,
    bodyText2: data.bodyText2?.json,
    bodyText3: data.bodyText3?.json,
  }
}

export const enableRelatedArticlesPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleRelatedArticles(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        backgroundImage{
          url
        }
        backgroundImage2{
          url
        }
        backgroundImage3{
          url
        }
        eyebrow
        eyebrow2
        eyebrow3
        headline
        headline2
        headline3
        linkToArticle
        linkToArticle2
        linkToArticle3
        generalTitle
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleRelatedArticles
  return {
    ...data,
    backgroundImage: data.backgroundImage?.url,
    backgroundImage2: data.backgroundImage2?.url,
    backgroundImage3: data.backgroundImage3?.url,
  }
}

export const enablePageDescriptionPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      modulePageDescription(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
       entryTitle
       __typename
        content{
          json
          __typename
          links{
            assets{
              hyperlink{
                sys{
                id
              }
                description
                url
                width
                height
              }
              block{
                sys{
                  id
                }
                description
                url
                width
                height
              }
            }
            entries{
              inline{
                sys{
                  id
                }
                __typename
              }
              hyperlink{
                sys{
                  id
                }
              }
              block{
                 sys{
                   id
                }
                __typename
                ... on ModuleMarkdownText {
                  markdownText
                }
              }
              __typename

            }
          }
        }
        inset
        paddings
        title
        textAlign

        cta {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }

        buttonTitle # deprecated
        sys{
          id,
          __typename
        }
    }
  }`,
    preview
  )
  const data = entry?.data?.modulePageDescription
  data?.content?.json?.content.map((item) => {
    if (item.nodeType == 'embedded-asset-block') {
      data?.content?.links?.assets?.block.map((image) => {
        if (item.data.target.sys.id == image.sys.id) {
          item.data.target.fields = {
            description: image.description,
            height: image.height,
            url: image.url,
            width: image.width,
          }
        }
      })
    }
  })

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data.content?.links?.entries?.block

  if (contentLinks && data.content?.json?.content) {
    parseEmbeddedMarkdown(data, 'content')
  }
  return {
    ...data,
    cta: {
      fields: {
        ...data.cta,
      },
    },
    initContent: data.content,
    content: data?.content?.json,
    linkAssets: data?.content?.links?.assets?.block,
    contentType: 'modulePageDescription',
  }
}

export const enableHeroTextPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleHeroText(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        bodyText{
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on ModuleMarkdownText {
                  markdownText
                }
              }
            }
          }
       }
       spaceBelowContent
       headline
       cta {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
       }
       # deprecated
       ctaLeftUrl
       ctaLeftText

       forArticle
       overlayType
       contentBlocksCollection{
        items{
          sys{
            id
          }
          bodyText{
            json
          }
          entryTitle
          headline
      }
       }
       informationBlocksCollection{
        items{
          sys{
            id
          }
          bodyText{
            json
          }
          entryTitle
          headline
        }
       }
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleHeroText
  const contentBlocks = data?.contentBlocksCollection?.items
  const informationBlocks = data?.informationBlocksCollection?.items
  contentBlocks?.map((item) => {
    item.fields = {
      headline: item.headline,
      entryTitle: item.entryTitle,
      bodyText: item.bodyText?.json,
    }
  })
  informationBlocks?.map((item) => {
    item.fields = {
      headline: item.headline,
      entryTitle: item.entryTitle,
      bodyText: item.bodyText?.json,
    }
  })

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data.bodyText?.links?.entries?.block

  if (contentLinks && data.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }

  return {
    ...data,
    bodyText: data.bodyText?.json,
    contentBlocks: contentBlocks,
    informationBlocks: informationBlocks,
  }
}

export const enableHeroBannerPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleHeroBanner(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        inset
        backgroundImage{
          url
        }
        overlayType
        headline
        eyebrow
        contentPosition
        fullHeight
        bodyText{
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on ModuleMarkdownText {
                  markdownText
                }
              }
            }
          }
        }
        contentPositionOnMobile
        subHeadline
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleHeroBanner

  // We need to attach the links (embededed content) to the bodyText
  const contentLinks = data.bodyText?.links?.entries?.block

  if (contentLinks && data.bodyText?.json?.content) {
    parseEmbeddedMarkdown(data, 'bodyText')
  }
  return {
    ...data,
    backgroundImage: data.backgroundImage?.url,
    bodyText: data.bodyText?.json,
  }
}

export const enableAccordionSectionPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleAccordionSection(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        sectionTitle
        sectionDescription{
          json
        }
        accordionTitle
        accordionDescription{
          json
        }
        sectionMarginTop
        tabsTitle

        cta {
          label
          url
          openInNewTab
          openVideoPlayer
          backgroundColor
        }

        buttonTitle
        buttonUrl
        accordionElementsCollection{
          items{
            groupTitle
            elementTitle
            elementDescription{
              json
            }
            elementImage{
              url
            }

            ctaLeft {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
            ctaRight {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }

            # deprecated
            buttonLeftTitle
            buttonLeftUrl
            buttonRightTitle
            buttonRightUrl
          }
        }
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleAccordionSection
  return {
    ...data,
    sectionDescription: documentToReactComponents(data.sectionDescription?.json),
    accordionDescription: documentToReactComponents(data.accordionDescription?.json),
    accordionElements: (data.accordionElementsCollection?.items).map((content, index) => {
      return {
        id: index,
        elementGroupTitle: content?.groupTitle || null,
        elementTitle: content?.elementTitle || null,
        elementDescription: documentToReactComponents(content?.elementDescription?.json) || null,
        elementImage: content?.elementImage ? `${content?.elementImage?.url}` : null,
        ctaLeft: content?.ctaLeft,
        ctaRight: content?.ctaRight,
        buttonLeftTitle: content?.buttonLeftTitle,
        buttonLeftUrl: content?.buttonLeftUrl || '/',
        buttonRightTitle: content?.buttonRightTitle,
        buttonRightUrl: content?.buttonRightUrl || '/',
      }
    }),
  }
}

export const enableImageGalleryUpPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleImageGalleryUp (id: "${id}",preview: ${preview ? 'true' : 'false'}) {
      eyebrow
      sys{
        id
      }
      sectionType
      headline
      marginTopBottom : marginTopbottom
      insert
      mosaicReverse
      imagesCollection{
        items{
          title
          url
        }
      }

    }
  }`,
    preview
  )
  const data = entry?.data?.moduleImageGalleryUp
  // console.log('--data---images---',data)
  return {
    ...data,
    images: (data.imagesCollection?.items).map((content, index) => {
      return {
        title: content?.title,
        url: content?.url,
      }
    }),
  }
}

export const enableMenuPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
        menu(id: "${id}",preview: ${preview ? 'true' : 'false'}){
          sys{
            id
            __typename
          }

          # Upper Bar fields fields
          entryTitle
          slug
          title
          searchPlaceholderText
          hasLogin
          hasCart

          # Upper Bar links - Deprecated
          menuItems:menuItemsCollection(limit:5){
            items{
              sys{
                id
              }
              entryTitle
              title
              type
              url
            }
          }

          # Lower Bar
          subMenu{
            sys{
              id
            }
            entryTitle
            accountLinkMobile
            wishlistLinkMobile

            # Lower Bar Top-level Menu Items
            contentBlocks:contentBlocksCollection(limit:6){
              items{
                sys{
                  id
                }
                categoryId
                categoryName
                categoryImageLink
                categoryImage{
                  url
                }
                navigationLink
                navigationTitle
                mobileNavigationLinkText
                productsList: productsListCollection {
                  items {
                    sys {
                      id
                    }
                    moduleName
                    productId
                  }
                }
                # deprecated
                highlights:highlightsCollection{
                  items{
                    sys{
                      id
                    }
                    title
                    link
                  }
                }

                # Lower Bar Sub Menu Items
                submenu:submenuCollection(limit:9){
                  items{
                    sys{
                      id
                    }
                    link
                    title
                    categoryId
                    categoryName
                    productsList: productsListCollection {
                      items {
                        sys {
                          id
                        }
                        moduleName
                        productId
                      }
                    }
                    # Category Items
                    subItems:subItemsCollection(limit:10){
                      items{
                        sys{
                          id
                        }
                        title
                        link

                        # deprecated
                        subItems:subItemsCollection(limit:10){
                          items{
                            sys{
                              id
                            }
                            title
                            link

                          }
                        }
                      }
                    }
                  }
                }

                # Square CTA
                squareCta {
                  sys {
                    id
                  }
                  title
                  header
                  text
                  link {
                    sys {
                      id
                    }
                    title
                    link
                  }
                  image {
                    sys {
                      id
                    }
                    url
                    title
                    description
                  }
                }

              }
            }

            # Lower Bar Menu Links (Menu item siblings)
            topLevelLinks:topLevelLinksCollection(limit:2){
              items{
                sys {
                  id
                }
                 title
                 link
              }
            }

            # Lower Bar Landscape CTA
            landscapeCta {
              sys {
                id
              }
              title
              header
              text
              link {
                sys {
                  id
                }
                title
                link
              }
              image {
                sys {
                  id
                }
                url
                title
                description
              }
            }

            # Mobile only menu links
            moduleFooter:moduleFooterCollection(limit:5){
              items{
                sys{
                  id
                }
                title
                link
              }
            }
          }
        }
  }`,
    preview
  )
  const data = entry?.data?.menu
  return { ...data }
}

export const enablesubMenuPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      navigationItem(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        subItems:subItemsCollection{
          items{
            sys{
              id
            }
            title
            link
            categoryId
            categoryName
            image {
              url
              title
              description
            }
            categoryName
            subItems:subItemsCollection{
              items{
                title
                link
                sys{
                  id
                }
              }
            }
          }
        }

        lowerSubMenuLink{
          sys {
            id
          }
          title
          link
        }
    }
  }`,
    preview
  )
  const data = entry?.data?.navigationItem
  // console.log('--data--sub menu---',entry)
  return data
}

export const enableFooterPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
    footer(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
    subscriptionTitle
    subscriptionTitleMobile
    subscriptionSubtitle
    contactNumber
    contactMessage
    contactWorkingHours
    emailCta
    legalMenu{
      menuItems:menuItemsCollection{
        items{
          url
          title
        }
      }
    }
    socialLinks{
      menuItems:menuItemsCollection{
        items{
          url
          title
        }
      }
    }
    menus:menusCollection{
      items{
        entryTitle
        slug
        title
        menuItems:menuItemsCollection{
          items{
            title
            url
          }
        }
      }
    }
  }
  }`,
    preview
  )
  const data = entry?.data?.footer
  const mapData = {
    legalMenu: {
      fields: { ...data.legalMenu, menuItems: data.legalMenu?.menuItems?.items },
    },
    menus: data.menus?.items,
    socialLinks: {
      menuItems: { ...data.socialLinks?.menuItems },
      fields: { ...data.socialLinks, menuItems: data.socialLinks?.menuItems?.items }, //data.socialLinks
    },
  }
  mapData?.legalMenu?.fields?.menuItems.map((item) => {
    item.fields = { ...item }
  })

  mapData?.socialLinks?.fields?.menuItems.map((item) => {
    item.fields = { ...item }
  })
  mapData?.menus?.map((item) => {
    item.fields = { ...item, menuItems: item.menuItems.items }
    item?.fields?.menuItems?.map((i) => {
      i.fields = { ...i }
    })
  })
  // console.log('--data--footer menu---',data, mapData)
  return { ...data, ...mapData }
}

export const enableContentBlockPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      contentBlock(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        coverImage{
          url
        }
        title
        eyebrow
        timeToRead
        excerpt
        slug
    }
  }`,
    preview
  )
  const data = entry?.data?.contentBlock
  // console.log('data-----',data);
  return {
    ...data,
  }
}

export const enableMarkdownTextPreview = async (module, preview = false) => {
  let id = module.sys.id
  const entry = await fetchGraphQL(
    `query {
      moduleMarkdownText(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
        markdownText
    }
  }`,
    preview
  )
  const data = entry?.data?.moduleMarkdownText?.markdownText

  return data
}

export const enablePromobarPreview = async (preview = false) => {
  const entry = await fetchGraphQL(
    `query {
      hellobarCollection(limit: 1, preview: ${preview ? 'true' : 'false'}) {
        items {
          show
          message {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on ModuleMarkdownText {
                    markdownText
                  }
                }
              }
            }
          }
          backgroundColor
          textColor
        }
    }
  }`,
    preview
  )
  const data = entry?.data?.hellobarCollection?.items[0] || null

  return data
}

export const enableModuleHeroPreview = async (module, preview = false) => {
  try {
    const id = module?.sys?.id
    const q = `
      query {
        moduleHero(id: "${id}", preview: ${preview ? 'true' : 'false'}) {
          type
          overlay
          alignment
          eyebrow
          headline
          backgroundImage {
            desktop {
              description
              url
            }
            tablet {
              description
              url
            }
            mobile {
              description
              url
            }
            altText
          }
          bodyText {
            json
            links {
              entries {
                block {
                  sys {
                    id
                  }
                  __typename
                  ... on ModuleMarkdownText {
                    markdownText
                  }
                }
              }
            }
          }
          video {
            url
          }
          spaceBelowContent
          spaceBelowTextContent
          categorySlugs
          productsCollection {
            items {
              productId
            }
          }
          ctasCollection {
            items {
              url
              label
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
          }
        }
      }
    `

    const entry = await fetchGraphQL(q, preview)

    if (entry?.data?.moduleHero) {
      let moduleHero = entry?.data?.moduleHero

      const contentLinks = moduleHero?.bodyText?.links?.entries?.block

      if (contentLinks && moduleHero?.bodyText?.json?.content) {
        parseEmbeddedMarkdown(moduleHero, 'bodyText')
      }

      return {
        fields: {
          alignment: moduleHero?.alignment || null,
          eyebrow: moduleHero?.eyebrow || null,
          headline: moduleHero?.headline || null,
          type: moduleHero?.type || null,
          alignment: moduleHero?.alignment || null,
          overlay: moduleHero?.overlay || null,
          bodyText: moduleHero?.bodyText?.json || null,
          spaceBelowContent: moduleHero?.spaceBelowContent || null,
          spaceBelowTextContent: moduleHero?.spaceBelowTextContent || null,
          video: moduleHero?.video
            ? {
                fields: {
                  file: {
                    url: moduleHero?.video?.url,
                  },
                },
              }
            : null,
          ctas:
            moduleHero?.ctasCollection?.items?.length > 0
              ? moduleHero?.ctasCollection?.items?.map((cta) => {
                  return {
                    fields: {
                      url: cta?.url || null,
                      label: cta?.label || null,
                      openInNewTab: cta?.openInNewTab || null,
                      openVideoPlayer: cta?.openVideoPlayer || null,
                      backgroundColor: cta?.backgroundColor || null,
                    },
                  }
                })
              : null,
          backgroundImage: moduleHero?.backgroundImage
            ? {
                fields: {
                  desktop: moduleHero?.backgroundImage?.desktop
                    ? {
                        fields: {
                          description: moduleHero?.backgroundImage?.desktop?.description,
                          file: {
                            url: (moduleHero?.backgroundImage?.desktop?.url).replace('https:', ''),
                          },
                        },
                      }
                    : null,
                  tablet: moduleHero?.backgroundImage?.tablet
                    ? {
                        fields: {
                          description: moduleHero?.backgroundImage?.tablet?.description,
                          file: {
                            url: (moduleHero?.backgroundImage?.tablet?.url).replace('https:', ''),
                          },
                        },
                      }
                    : null,
                  mobile: moduleHero?.backgroundImage?.mobile
                    ? {
                        fields: {
                          description: moduleHero?.backgroundImage?.mobile?.description,
                          file: {
                            url: (moduleHero?.backgroundImage?.mobile?.url).replace('https:', ''),
                          },
                        },
                      }
                    : null,
                  altText: moduleHero?.backgroundImage?.altText || '',
                },
              }
            : null,
          products:
            moduleHero?.productsCollection?.items?.length > 0
              ? moduleHero?.productsCollection?.items?.map((product) => {
                  return {
                    fields: {
                      productId: product?.productId,
                    },
                  }
                })
              : null,
        },
      }
    }
  } catch (error) {
    return module
  }
}

export const enable3upFeature = async (module, preview = false) => {
  try {
    const id = module?.sys?.id
    const q = `query {
      module3UpFeature(id: "${id}", preview: ${preview ? 'true' : 'false'}) {
        title
        image1 {
          description
          url
        }
        title1
        linkText1
        link1
        image2 {
          description
          url
        }
        title2
        linkText2
        link2
        image3 {
          description
          url
        }
        title3
        linkText3
        link3
      }
  }`

    const entry = await fetchGraphQL(q, preview)

    if (entry?.data?.module3UpFeature) {
      let module3UpFeature = entry?.data?.module3UpFeature
      return {
        title: module3UpFeature?.title ?? null,
        image1: module3UpFeature?.image1
          ? {
              fields: {
                file: {
                  url: module3UpFeature?.image1?.url?.replace('https:', ''),
                },
                description: module3UpFeature?.image1?.description,
              },
            }
          : null,
        title1: module3UpFeature?.title1 ?? null,
        linkText1: module3UpFeature?.linkText1 ?? null,
        link1: module3UpFeature?.link1 ?? null,
        image2: module3UpFeature?.image2
          ? {
              fields: {
                file: {
                  url: module3UpFeature?.image2?.url?.replace('https:', ''),
                },
                description: module3UpFeature?.image2?.description,
              },
            }
          : null,
        title2: module3UpFeature?.title2 ?? null,
        linkText2: module3UpFeature?.linkText2 ?? null,
        link2: module3UpFeature?.link2 ?? null,
        image3: module3UpFeature?.image3
          ? {
              fields: {
                file: {
                  url: module3UpFeature?.image3?.url?.replace('https:', ''),
                },
                description: module3UpFeature?.image3?.description,
              },
            }
          : null,
        title3: module3UpFeature?.title3 ?? null,
        linkText3: module3UpFeature?.linkText3 ?? null,
        link3: module3UpFeature?.link3 ?? null,
      }
    }

    return data
  } catch (error) {
    return module
  }
}

export const enableBitsTablePreview = async (module, preview = false) => {

  try {
    let id = module.sys.id
    const entry = await fetchGraphQL(
      `query {
        bitsTable(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          tableTitle,
          table
      }
    }`,
      preview
    )

    return {
      fields: {
        tableTitle: entry?.data?.bitsTable?.tableTitle ?? null,
        table: entry?.data?.bitsTable?.table ?? null,
      }
    }
  } catch (error) {
    return module
  }

}